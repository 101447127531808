import React, { Component } from 'react';
import { EventName } from '../../services/dystirWebClientService';
import MatchDate from '../../extentions/matchDate';
import { format } from 'react-string-format';
import { GiSoccerBall } from "react-icons/gi";
import { FaStar } from "react-icons/fa";

export class SummaryEventView extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const match = this.props.match;
        const event = this.props.event;
        
        let contents =
            <table>
                <tbody>
                    <tr>
                    {
                        event.eventTeam == match.homeTeam &&
                        <>
                            <td className="minutes_summary">
                            {
                                event.eventName == EventName.PLAYEROFTHEMATCH &&
                                <div className="player_of_the_match">Dagsins leikari</div> ||
                                <div>
                                {
                                    this.getEventMinute(event)
                                }
                                </div>
                            }
                            </td>
                            {
                                event.eventName == EventName.PENALTYSCORED &&
                                <td>
                                    <GiSoccerBall className="goal_icon" />
                                </td>
                            }
                            <td className={"summary_event "}>
                            {
                                event.eventName == EventName.GOAL &&
                                <>
                                    <GiSoccerBall className="goal_icon" />
                                    <span style={{ margin: "0 5px" }}>Mál</span>
                                </> ||
                                event.eventName == EventName.OWNGOAL &&
                                <>
                                    <GiSoccerBall className="owngoal_icon" />
                                    <span style={{ margin: "0 5px" }}>Sjálvmál</span>
                                </> ||
                                event.eventName == EventName.PENALTYSCORED &&
                                <>
                                    <span style={{ padding: "0 5px" }}>Brotsspark</span>
                                    <br/>
                                    <span style={{ padding: "0 5px" }}>skora</span>
                                </> ||
                                event.eventName == EventName.PENALTYMISSED &&
                                <>
                                    <span className="penalty_missed" style={{ padding: "0 5px 0 0" }}>Brotsspark</span>
                                    <br />
                                    <span className="penalty_missed" style={{ padding: "0 5px 0 0"}}>brent</span>
                                </> ||
                                event.eventName == EventName.YELLOW &&
                                    <span className="yellow_card"></span> ||
                                event.eventName == EventName.RED &&
                                    <span className="red_card"></span> ||
                                event.eventName == EventName.SUBSTITUTION &&
                                <>
                                    <div className="sub_in">&#9650;</div>
                                    <div className="sub_out">&#9660;</div>
                                </> ||
                                event.eventName == EventName.PLAYEROFTHEMATCH &&
                                <FaStar style={{ color: "gold" }} />
                            }
                            </td>
                        </>
                    }
                    { 
                        event.eventTeam == match.awayTeam &&
                        <>
                            <td className={"summary_event "}>
                            {
                                event.eventName == EventName.GOAL &&
                                <>
                                    <span style={{ margin: "0 5px" }}>Mál</span>
                                    <GiSoccerBall className="goal_icon" />
                                </> ||
                                event.eventName == EventName.OWNGOAL &&
                                <>
                                    <span style={{ margin: "0 5px" }}>Sjálvmál</span>
                                    <GiSoccerBall className="owngoal_icon" />
                                </> ||
                                event.eventName == EventName.PENALTYSCORED &&
                                <>
                                    <span style={{ padding: "0 5px" }}>Brotsspark</span>
                                    <br />
                                    <span style={{ padding: "0 5px" }}>skora</span>
                                </> ||
                                event.eventName == EventName.PENALTYMISSED &&
                                <>
                                    <span className="penalty_missed" style={{ padding: "0 0 0 5px" }}>Brotsspark</span>
                                    <br />
                                    <span className="penalty_missed" style={{ padding: "0 0 0 5px" }}>brent</span>
                                </> ||
                                event.eventName == EventName.YELLOW &&
                                <span className="yellow_card"></span> ||
                                event.eventName == EventName.RED &&
                                <span className="red_card"></span> ||
                                event.eventName == EventName.SUBSTITUTION &&
                                <>
                                    <div className="sub_in">&#9650;</div>
                                    <div className="sub_out">&#9660;</div>
                                </> ||
                                event.eventName == EventName.PLAYEROFTHEMATCH &&
                                <FaStar style={{ color: "gold" }} />
                            }
                            </td>
                            {
                                event.eventName == EventName.PENALTYSCORED &&
                                <td>
                                    <GiSoccerBall className="goal_icon" />
                                </td> 
                            }
                            <td className="minutes_summary">
                            {
                                event.eventName == EventName.PLAYEROFTHEMATCH &&
                                <div className="player_of_the_match">Dagsins leikari</div> ||
                                <div>
                                {
                                    this.getEventMinute(event)
                                }
                                </div>
                            }
                            </td>
                        </>
                    }
                    </tr>
                </tbody>
            </table>
        return contents
    }

    getEventMinute(event) {
        switch (event.eventPeriodId) {
            case 1:
                return "";
            case 3:
                return "46'";
            case 5:
                return "91'";
            case 7:
                return "106'";
            case 9:
                return "120'";
            case 10:
                return "120'";
            case 11:
            case 12:
            case 13:
                return "";
            default:
                return event.eventMinute;
        }
    }
}
