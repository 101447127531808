import React, { Component, useCallback, useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test secret API key.
const stripePromise = loadStripe('pk_test_51QExW4INSvQmBV2LVCBFPSXWHtsxRGbIRRNlLQ78ExUz1O1YRSIXM2fWdwqdGCpFUxUpLB5UT3yLZANGdGvtEAoi00jHEkJoMb');

export const StripPayment = () => {
    const fetchClientSecret = useCallback(() => {
        const response = fetch('api/checkout');
        alert(response);
        // Create a Checkout Session
        return fetch("api/checkout", {
            method: "POST",
        })
            .then((res) => res.json())
            .then((data) => data.clientSecret);
    }, []);

    const options = { fetchClientSecret };

    return (
        <div id="checkout">
            <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
            >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </div>
    )
}
